import React, { useState } from 'react';
import { Alert, Button, Typography, Box, TextField, Snackbar } from '@mui/material';
import { Google as GoogleIcon, Facebook as FacebookIcon } from '@mui/icons-material';
import useWindowSize from '../../hooks/useWindowSize';
import { signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import { authCallback, checkPaymentStatus } from '../../api';
import CheckIcon from '@mui/icons-material/Check';



const LoginForm = () => {
    const navigate = useNavigate();

    const { width } = useWindowSize();
    const isMobile = width < 768;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleEmailLoginOrSignup = async () => {
        try {
            let userCredential;

            // First, try to sign in
            try {
                userCredential = await signInWithEmailAndPassword(auth, email, password);
            } catch (signInError) {
                // If sign in fails, try to create a new account
                if (signInError.code === 'auth/user-not-found') {
                    userCredential = await createUserWithEmailAndPassword(auth, email, password);
                } else {
                    throw signInError; // Re-throw if it's a different error
                }
            }

            const user = userCredential.user;
            const idToken = await user.getIdToken();

            // Send ID token to your server
            const response = await authCallback(idToken);

            if (!response.ok) {
                throw new Error('Failed to authenticate. Try again later');
            }

            // Check payment status
            const paymentResponse = await checkPaymentStatus(idToken);

            if (paymentResponse.isPaid) {
                navigate('/analyze');
            } else {
                navigate('/payment');
            }

        } catch (error) {
            console.error('Error during authentication:', error);
            setError(error.message);
        }
    };

    const handleErrorClose = () => {
        setError(null);
    };

    const handleSocialLogin = async (providerName) => {
        let provider;
        if (providerName === 'google') {
            provider = new GoogleAuthProvider();
        } else if (providerName === 'facebook') {
            provider = new FacebookAuthProvider();
        }

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const idToken = await user.getIdToken();

            // Send ID token to your server
            const response = await authCallback(idToken);

            if (!response.ok) {
                throw new Error('Failed to authenticate with this is social');
            }

            // Check payment status
            const paymentResponse = await checkPaymentStatus(idToken);

            if (paymentResponse.isPaid) {
                navigate('/analyze');
            } else {
                navigate('/payment');
            }

        } catch (error) {
            console.error("Error during social login:", error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <Box
            className={`flex flex-col ${isMobile ? 'min-h-screen pb-24' : 'min-h-screen'}`}
            sx={{
                overflowY: 'auto',
                paddingTop: isMobile ? '1rem' : '2rem',
                paddingBottom: '2rem',
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                }
            }}
        >
            <Box className={`container mx-auto px-4 ${isMobile ? 'max-w-full' : 'max-w-md'} flex-grow`}>
                <Typography variant={isMobile ? "h5" : "h4"} className="font-serif mb-4 text-center">
                    Login or Create An Account
                </Typography>
                <Box className='grid gap-2 mb-4'>
                    {[
                        <><strong>Never throw away something that's truly valuable.</strong></>,
                        <>Make sure everything you own aligns with your values.</>,
                        <><strong>Know the true worth of what you're buying</strong> before you pull the trigger.</>,
                        <>Trained on the most reputable data sources to assess <strong>material quality</strong>, carbon footprint, <strong>current and future market demand</strong>, and more.</>
                    ].map((text, index) => (
                        <div key={index} className='flex items-start'>
                            <CheckIcon fontSize="small" className="mr-2 mt-1 flex-shrink-0" />
                            <Typography variant="body2" className='font-serif text-left'>
                                {text}
                            </Typography>
                        </div>
                    ))}
                </Box>
                <div className="flex flex-col items-center space-y-3 w-full">
                    <Button
                        variant="contained"
                        color="secondary"
                        className="font-serif bg-orange-50 text-black rounded-full w-full h-12"
                        startIcon={<GoogleIcon />}
                        onClick={() => handleSocialLogin('google')}
                    >
                        Google
                    </Button>
                    {/* <Button
                        variant="contained"
                        color="primary"
                        className="font-serif bg-orange-50 text-black rounded-full w-full h-12"
                        startIcon={<FacebookIcon />}
                        onClick={() => handleSocialLogin('facebook')}
                    >
                        Facebook
                    </Button> */}
                </div>
                <Typography className="font-serif my-3 text-center">or</Typography>
                <div className="flex flex-col items-center space-y-3 w-full">
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            style: { fontFamily: 'serif' },
                        }}
                        InputLabelProps={{
                            style: { fontFamily: 'serif' },
                        }}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            style: { fontFamily: 'serif' },
                        }}
                        InputLabelProps={{
                            style: { fontFamily: 'serif' },
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className="font-serif bg-orange-50 text-black rounded-full w-full h-12"
                        onClick={handleEmailLoginOrSignup}
                    >
                        Sign In with Email
                    </Button>
                </div>
            </Box>

            {error && (
                <Snackbar open={true} autoHideDuration={6000} onClose={handleErrorClose}>
                    <Alert onClose={handleErrorClose} severity="error" className="font-serif">
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default LoginForm;