import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Box, Paper } from '@mui/material';
import { createCheckoutSession } from '../api';
import useWindowSize from '../hooks/useWindowSize';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { getPhoto } from '../utils/cacheManager';

const PaymentPage = ({ storedPhoto }) => {
    const [photoPreview, setPhotoPreview] = useState(null);
    const { width, height } = useWindowSize();
    const isMobile = width < 768;
    const isShortScreen = height < 700;
    const navigate = useNavigate();
    const blobUrlRef = useRef(null);

    useEffect(() => {
        const loadPhotoFromCache = async () => {
            if (storedPhoto && storedPhoto.url) {
                try {
                    const photoBlob = await getPhoto(storedPhoto.url);
                    if (photoBlob) {
                        if (blobUrlRef.current) {
                            URL.revokeObjectURL(blobUrlRef.current);
                        }
                        blobUrlRef.current = URL.createObjectURL(photoBlob);
                        setPhotoPreview(blobUrlRef.current);
                    }
                } catch (error) {
                    console.error('Error loading photo from cache:', error);
                }
            }
        };

        loadPhotoFromCache();

        // Cleanup function
        return () => {
            if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
                blobUrlRef.current = null;
            }
        };
    }, [storedPhoto]);

    const handleCheckout = async (serviceType) => {
        try {
            const session = await createCheckoutSession(serviceType);
            if (session.url) {
                window.location.href = session.url;
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            navigate('/payments');
        }
    };

    const PricingOption = ({ title, price, onClick, isMobile, isBestValue, isMostPopular }) => (
        isMobile ? (

            <Paper elevation={2} className="rounded-lg overflow-hidden">
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="font-serif bg-orange-50 text-black py-3 flex flex-col items-start"
                    onClick={onClick}
                >
                    <Typography variant="subtitle1" className="font-serif font-bold text-left">
                        {title}
                    </Typography>
                    {price && (
                        <Typography variant="body2" className="font-serif text-left">
                            {price}
                        </Typography>
                    )}
                </Button>
                {isBestValue && (
                    <Box className="bg-gradient-to-r from-pink-300 to-blue-300 px-2 py-1">
                        <Typography variant="caption" className="font-serif text-black font-bold">
                            Best Value
                        </Typography>
                    </Box>
                )}
                {isMostPopular && (
                    <Box className="bg-gradient-to-r from-pink-300 to-blue-300 px-2 py-1">
                        <Typography variant="caption" className="font-serif text-black font-bold">
                            Most Popular
                        </Typography>
                    </Box>
                )}
            </Paper>
        ) : (
            <Box className={`pricing-option relative ${isMobile ? 'mb-4' : 'flex flex-col grow justify-between'} rounded-xl overflow-hidden ${isMobile ? 'p-2' : 'p-6 xl:p-8'} ring-1 ring-gray-900/10`}>
                <Box className="absolute inset-0 bg-gradient-to-br from-pink-200 to-blue-200 opacity-10"></Box>
                {!isMobile && (
                    <>
                        <Typography variant="h6" className="font-serif mb-2">{title}</Typography>
                        {price && <Typography variant="h4" className="font-serif mb-4">{price}</Typography>}
                    </>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onClick}
                    className={`font-serif bg-orange-50 text-black rounded-lg ${isMobile ? 'h-12' : 'h-16 mt-auto'}`}
                >
                    <Typography variant="body1" className="font-serif font-bold">
                        Choose this plan
                    </Typography>
                </Button>
            </Box>
        )
    );

    const BestValueLabel = ({ isMobile }) => (
        <Box className={`absolute ${isMobile ? 'top-1 right-1' : 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'} bg-gradient-to-r from-pink-300 to-blue-300 px-2 py-0.5 rounded-full z-10`}>
            <Typography variant={isMobile ? "caption" : "subtitle2"} className="font-serif text-black">
                Best Value
            </Typography>
        </Box>
    );

    const MostPopularLabel = ({ isMobile }) => (
        <Box className={`absolute ${isMobile ? 'top-1 right-1' : 'top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'} bg-gradient-to-r from-pink-300 to-blue-300 px-2 py-0.5 rounded-full z-10`}>
            <Typography variant={isMobile ? "caption" : "subtitle2"} className="font-serif text-black">
                Most Popular
            </Typography>
        </Box>
    );


    return (
        <Box className={` ${isMobile ? "" : " grid px-4 mb-4 mt-24"}`}>
            {photoPreview ? (
                <Typography variant={isMobile ? "h4" : "h3"} className={`font-display ${isMobile && storedPhoto && 'mb-4 text-3xl'} ${!storedPhoto ? 'text-4xl mb-12' : ''}`} gutterBottom>
                    Choose Your Plan To See Results
                </Typography>
            ) :
                (<Typography variant={isMobile ? "h5" : "h3"} className={`font-display ${isMobile && storedPhoto && 'mb-4 text-3xl'} ${!storedPhoto ? 'text-4xl mb-12' : ''}`} gutterBottom>
                    Choose Your Plan
                </Typography>
                )}
            {photoPreview &&
                <Box id="photo-container" className={`${isShortScreen ? 'h-40 w-auto' : 'w-auto h-48'} my-0 mx-auto mb-4`}>
                    <Box className="relative w-auto max-h-48  ">
                        <img src={photoPreview} alt="Uploaded" className="shadow-xl my-0 mx-auto rounded" />
                    </Box>
                </Box>
            }
            <Box className='grid gap-2 mb-4 mx-2'>
                {[
                    <> <strong>Never throw away something that's truly valuable.</strong></>,
                    <><strong>Know the true worth of what you're buying</strong> before you pull the trigger.</>,
                    <>Trained on the most reputable data sources to assess <strong>material quality</strong>, carbon footprint, <strong>current and future market demand</strong>, and more.</>
                ].map((text, index) => (
                    <div key={index} className='flex items-start'>
                        <CheckIcon fontSize="small" className="mr-2 mt-1 flex-shrink-0" />
                        <Typography variant="body2" className='font-serif text-left'>
                            {text}
                        </Typography>
                    </div>
                ))}
            </Box>
            <Box className={`pricing-options isolate mx-auto grid max-w-md grid-cols-1  ${isMobile ? 'gap-y-4' : 'gap-y-8 md:gap-x-8 md:mx-0 md:max-w-none md:grid-cols-3'}`}>
                {isMobile ? (
                    <Box className="pricing-options isolate mx-auto grid grid-cols-1 gap-y-2 max-w-md w-full">
                        <PricingOption
                            title="7-Day Free Trial"
                            onClick={() => handleCheckout('weekly')}
                            isMobile={true}
                            isMostPopular={true}
                        />
                        <PricingOption
                            title="Monthly $7"
                            onClick={() => handleCheckout('monthly')}
                            isMobile={true}
                        />
                        <Box className="relative">
                            <PricingOption
                                title="Annual $99"
                                onClick={() => handleCheckout('annual')}
                                isMobile={true}
                                isBestValue={true}

                            />
                        </Box>
                    </Box>
                ) : (
                    <>
                        <Box className="relative">
                            <MostPopularLabel />
                            <PricingOption
                                title="Weekly Access"
                                price="7-Day Free Trial"
                                onClick={() => handleCheckout('weekly')}
                            />
                        </Box>
                        <PricingOption
                            title="Monthly Access"
                            price="$7"
                            onClick={() => handleCheckout('monthly')}
                        />
                        <Box className="relative">
                            <BestValueLabel />
                            <PricingOption
                                title="Annual Access"
                                price="$47"
                                onClick={() => handleCheckout('annual')}
                            />
                        </Box>
                    </>

                )

                }

            </Box>
        </Box >

    );
};

export default PaymentPage;
